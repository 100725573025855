<template lang="pug">
  .ghost-filter(:class="{'ghost-filter--active': active}")
    .ghost-filter__wrapper
      p.ghost-filter_title.unselectable
        span {{ label }}
        transition(name="fade")
          span(v-if="value") &nbsp; {{ value }}

    img(src="@/assets/img/ui/close.svg" @click="$emit('clear', $event)").close-icon
</template>

<script>
export default {
  name: 'GhostFilter',

  props: {
    label: String,
    value: {
      required: true
    },
    active: Boolean
  }
}
</script>

<style lang="scss" scoped>
.ghost-filter {
  position: relative;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  min-width: 140px;
  max-width: 140px;
  display: flex;
  border-radius: 4px;
  background-color: #f5f7f9;
  cursor: pointer;
  margin-right: 15px;

  @include media("<=tablet") {
    min-width: 130px;
    max-width: 130px;
    margin: 0 5px 5px 0;
  }

  &__wrapper {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}

.ghost-filter_title {
  font-size: 11px;
  min-width: 60px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $placeholder-color;
  margin-bottom: 0 !important;
  transition: color 0.3s ease-in-out;

  .ghost-filter--active & {
    transition: color 0.3s ease-in-out;
    color: #272c32;
  }
}

.close-icon {
  display: none;
  width: 26px;
  height: 26px;
  position: absolute;
  top: -10px;
  left: auto;
  right: -10px;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;

  .ghost-filter--active & {
    display: block;
    transition: opacity 0.3s ease-in-out;
  }
}
</style>